import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {RoleAccess} from '../../../models/users/Users'
import {usersAPI} from '../../../redux/services/UsersService'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {buttonsBYRatingScale} from '../helpers/control-helpers'
import {controlsAPI} from '../../../redux/services/ControlsService'
import {ICriteria, IScoreForms, IScoreType} from '../../../models/score/Score'
import {
  IControlData,
  IControlScore,
  IControlScored,
  IControlScoredDetail,
  IScoreValue,
} from '../models/controls'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../../_metronic/helpers/FormatDateZoneHelpers'
import {InfoCriterionModal} from '../../tools/components/criteria/modals/InfoCriterionModal'

type Props = {
  controlSingle: IControlData
}

const ControlForm: FC<Props> = ({controlSingle}) => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [control, setControlState] = useState<IControlData>(controlSingle)
  const {data: forms} = scoreAPI.useFormsFetchQuery(control.created_by.id)
  const [getFormById] = scoreAPI.useLazyFormsByIdQuery()
  const {data: formSingle} = scoreAPI.useFormsByIdQuery(
    {id: control.score?.score_form_id!},
    {skip: control.check_status === 'WAIT'}
  )
  const [comment, setComment] = useState<string>()
  const [score, setScore] = useState<IControlScore>()
  const [fetching, setFetching] = useState<boolean>(false)
  const [showComment, setShowComment] = useState<boolean>(true)
  const [isShowOperator, setIsShowOperator] = useState<boolean>(true)
  const [selectedForm, setSelectedForm] = useState<IScoreForms>()
  const [infoCriterion, setInfoCriterion] = useState<ICriteria>()
  const [showModalInfoCriterion, setShowModalInfoCriterion] = useState<boolean>(false)
  const [saveScore] = controlsAPI.useControlScoreSaveMutation()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])
  const [ignoreCriterionState, setIgnoreCriterionState] = useState<string[]>([])

  useEffect(() => {
    if (control.check_status !== 'WAIT') {
      handleScoredCurrentForm(control.score!.score_form_id!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSingle])

  useEffect(() => {
    setControlState(controlSingle)
    setSelectedForm(undefined)
  }, [controlSingle])

  const getScore = () => {
    let xMax = 0
    let x = 0
    let xMin = 0
    let xAvr = 0
    if (
      selectedForm?.criterions &&
      selectedForm?.criterions?.length > 0 &&
      selectedForm.criterions.length !== ignoreCriterionState.length
    ) {
      for (let key in selectedForm?.criterions) {
        const cr = selectedForm?.criterions[parseInt(key)]
        if (ignoreCriterionState.indexOf(cr.id) === -1) {
          xMax = xMax + cr.score_zone.MAX_RANGE_MAX!
          xMin = xMin + cr.score_zone.MIN_RANGE_MIN!

          /*if (cr.rating_scale === 'BINARY_SCALE' || cr.rating_scale === 'BINARY_TEXT_SCALE') total++*/
          if (score!.detail.get(cr.id) !== null) {
            x = x + score!.detail.get(cr.id)!
          } else {
            setScore({
              ...score!,
              values: [
                {score_type: 'PERCENT_SIMPLE', value: 0},
                {score_type: 'NUMBER_AVERAGE_SIMPLE', value: 0},
                {score_type: 'NUMBER_SUM_SIMPLE', value: 0},
              ],
            })
            return
          }
        }
      }
      xAvr = x / (selectedForm.criterions.length - ignoreCriterionState.length)
      const r = ((x - xMin) / (xMax - xMin)) * 100
      setScore({
        ...score!,
        values: [
          {score_type: 'PERCENT_SIMPLE', value: parseFloat(r.toFixed(1))},
          {score_type: 'NUMBER_AVERAGE_SIMPLE', value: parseFloat(xAvr.toFixed(1))},
          {score_type: 'NUMBER_SUM_SIMPLE', value: x},
        ],
      })
    } else {
      setScore({
        ...score!,
        values: [
          {score_type: 'PERCENT_SIMPLE', value: 0},
          {score_type: 'NUMBER_AVERAGE_SIMPLE', value: 0},
          {score_type: 'NUMBER_SUM_SIMPLE', value: 0},
        ],
      })
    }
  }

  const findNullDetail = () => {
    let check: boolean = false
    score?.detail.forEach((value, key) => {
      if (ignoreCriterionState.indexOf(key) === -1 && value === null) {
        check = true
      }
    })
    return check
  }

  const handleSaveScore = () => {
    const cr: IControlScoredDetail[] = []
    if (score?.detail !== undefined) {
      score.detail.forEach((v, k, map) => {
        cr.push({criterion_id: k, criterion_value: v !== null ? v : -2})
      })
    }

    let values: IScoreValue[] = []

    selectedForm?.types.forEach((t: IScoreType) => {
      if (score?.values && t in score?.values!) {
        values.push(score?.values[Object.keys(score.values).indexOf(t)])
      }
    })

    const data: IControlScored = {
      score_form_id: score?.score_form_id,
      created_on: control.score?.created_on,
      values: score?.values!,
      detail: cr,
    }

    if (comment !== undefined) {
      data['comments'] = {is_show_operator: isShowOperator, comment: comment}
    }

    saveScore({
      id: control.id,
      data: data,
    })
      .unwrap()
      .then((response) => {
        setControlState(response)
        setIgnoreCriterionState([])
      })
  }

  const handleScoredCurrentForm = (id: string) => {
    setSelectedForm(formSingle)
    const scoreCriterions: Map<string, number> = new Map<string, number>()
    control.score?.detail.forEach((c) => scoreCriterions.set(c.criterion_id, c.criterion_value))
    setScore({
      score_form_id: control.score?.score_form_id,
      values: [],
      detail: scoreCriterions,
    })
  }

  const handleCurrentForm = (id: string) => {
    getFormById({id: id}).then((response) => {
      setSelectedForm(response.data)
      setFetching(false)
      const scoreCriterions: Map<string, number | null> = new Map<string, number | null>()
      response.data?.criterions?.forEach((c) => scoreCriterions.set(c.id, null))
      setScore({
        score_form_id: response.data?.id,
        values: [],
        detail: scoreCriterions,
      })
    })
    setFetching(true)
  }

  const handleShowInfoCriterion = (cr: ICriteria) => {
    setInfoCriterion(cr)
    setShowModalInfoCriterion(true)
  }

  const handleCloseInfoCriterion = () => {
    setInfoCriterion(undefined)
    setShowModalInfoCriterion(false)
  }

  const handleBack = () => {
    setSelectedForm(undefined)
    setScore(undefined)
    setIgnoreCriterionState([])
    setComment(undefined)
    setShowComment(false)
  }

  const handleCriterionValue = (id: string, value: number) => {
    const newDetailMap = score?.detail!
    newDetailMap?.set(id, value)
    setScore({...score!, detail: newDetailMap})
    getScore()
  }

  const handleResetValue = (id: string) => {
    const newDetailMap = score?.detail!
    newDetailMap?.set(id, null)
    setScore({...score!, detail: newDetailMap})
    getScore()
  }

  useEffect(() => {
    getScore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreCriterionState])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      {control.check_status === 'WAIT' ? (
        <>
          {fetching ? (
            <div className='d-flex card card-custom card-border'>
              <div className='overlay overlay-block'>
                <div className='card-header border-0 py-3'>
                  <div className='overlay-layer card-rounded bg-opacity-5'>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {selectedForm === undefined ? (
                <>
                  {forms !== undefined && forms.length > 0 ? (
                    <div className='card card-custom card-border'>
                      <div className='card-header mb-3'>
                        <div className='card-title'>
                          <h4 className='fw-bold small text-gray-700 text-uppercase'>
                            Выберите форму оценки для проверки
                          </h4>
                        </div>
                      </div>
                      {forms?.map((f, i) => {
                        const departmentsName = f.departments.map((d, i, arr) => {
                          if (arr.length - 1 === i) {
                            return d.name
                          } else {
                            return d.name + ', '
                          }
                        })
                        return (
                          <div key={i} className='card-body px-5 py-0 mb-3'>
                            <div className='overflow-hidden flex-column-fluid p-0'>
                              <div className='d-flex fw-bold small py-2 px-4 rounded bg-gray-200 align-items-center'>
                                <div
                                  className='bd-highlight w-100 cursor-pointer'
                                  onClick={() => {
                                    if (!roleAccesses.includes('CONTROLSOWN')) {
                                      handleCurrentForm(f.id)
                                    }
                                  }}
                                >
                                  {/* <span className='text-gray-400 small'>{f.id}</span> */}
                                  <div className='menu-title text-gray-900 text-uppercase'>
                                    {f.name}
                                  </div>
                                  <div className='fw-bold label text-gray-700 small'>
                                    {f.description}
                                  </div>
                                  <div className='fw-bold label text-gray-600 small'>
                                    {departmentsName}
                                  </div>
                                </div>
                                {!roleAccesses.includes('CONTROLSOWN') && (
                                  <button
                                    className='btn btn-custom p-0'
                                    onClick={() => handleCurrentForm(f.id)}
                                  >
                                    <span className='svg-icon svg-icon-1'>
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr024.svg'
                                        className='svg-icon svg-icon-2 text-hover-primary'
                                      />
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <>
                      <div className='alert alert-primary d-flex align-items-center p-5'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen044.svg'
                          className='svg-icon svg-icon-3x text-primary me-2'
                        />
                        <div className='d-flex flex-column'>
                          <h4 className='mb-1 text-dark'>No Forms</h4>
                          <span>
                            No "PUBLISHED" forms, where the department corresponds to the department
                            of the responsible user for the call.
                          </span>
                          <Link to='/tools/score_forms/' className='fw-bold text-warning'>
                            Go to forms
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className='card card-custom card-border'>
                  <div className='card-header mb-3'>
                    <button className='btn btn-custom p-0' onClick={handleBack}>
                      <span className='svg-icon svg-icon-1'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr021.svg'
                          className='svg-icon svg-icon-2 text-hover-primary'
                        />
                      </span>
                    </button>
                    <div className='card-title flex-column align-items-end justify-content-center'>
                      <div className='card-label fw-bold text-gray-800 text-uppercase small m-0'>
                        {selectedForm.name}
                      </div>
                      <div className='fw-bold label text-gray-700 small'>
                        {selectedForm.description}
                      </div>
                    </div>
                  </div>
                  <div className='card-body px-5 py-0'>
                    {selectedForm.criterions?.map((c, i) => (
                      <div key={i} className='overflow-hidden flex-column-fluid p-0 mb-4'>
                        <div
                          className={clsx('rounded bg-gray-200 py-2 px-4', {
                            'bg-gray-300': ignoreCriterionState.indexOf(c.id) !== -1,
                          })}
                        >
                          <div className='d-flex fw-bold small align-items-center mt-1'>
                            <div className='bd-highlight w-100'>
                              <div className='menu-title text-gray-900 text-uppercase'>
                                {c.name}
                              </div>
                            </div>
                            <i
                              className='bi bi-info-circle fs-2 text-hover-primary ms-2 cursor-pointer'
                              onClick={() => handleShowInfoCriterion(c)}
                            />
                          </div>
                          <div className='separator my-3'></div>
                          <div className='d-flex align-items-center mb-1'>
                            <button
                              className='btn btn-icon me-2'
                              onClick={() => handleResetValue(c.id)}
                              disabled={ignoreCriterionState.indexOf(c.id) !== -1}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2 text-gray-800 text-hover-primary m-0 p-0'
                              />
                            </button>
                            {buttonsBYRatingScale(c.rating_scale).map((el, i) => {
                              if (
                                c.rating_scale === 'BINARY_SCALE' ||
                                c.rating_scale === 'BINARY_TEXT_SCALE'
                              ) {
                                return (
                                  <div key={i}>
                                    <button
                                      disabled={ignoreCriterionState.indexOf(c.id) !== -1}
                                      className={clsx(
                                        'btn btn-sm btn-outline btn-outline-gray me-2',
                                        {
                                          active: score?.detail.get(c.id) === el,
                                        },
                                        {'btn-active-success': el},
                                        {'btn-active-danger': !el}
                                      )}
                                      onClick={() => handleCriterionValue(c.id, el)}
                                    >
                                      {c.rating_scale === 'BINARY_TEXT_SCALE' ? (
                                        <span className='small'>
                                          {el === 0 ? <>NO</> : <>YES</>}
                                        </span>
                                      ) : (
                                        <span className='small'>{el}</span>
                                      )}
                                    </button>
                                    <div
                                      className={clsx(
                                        'bg-primary rounded me-2 mt-1 h-5px',
                                        {'bg-success': el},
                                        {'bg-danger': !el}
                                      )}
                                    ></div>
                                  </div>
                                )
                              }
                              return (
                                <div key={i}>
                                  <button
                                    disabled={ignoreCriterionState.indexOf(c.id) !== -1}
                                    className={clsx(
                                      'btn btn-sm btn-outline btn-outline-gray me-2',
                                      {active: score?.detail.get(c.id) === el},
                                      {
                                        'btn-active-success':
                                          el <= c.score_zone.MAX_RANGE_MAX! &&
                                          el >= c.score_zone.MAX_RANGE_MIN!,
                                      },
                                      {
                                        'btn-active-warning':
                                          el <= c.score_zone.AVERAGE_RANGE_MAX! &&
                                          el >= c.score_zone.AVERAGE_RANGE_MIN!,
                                      },
                                      {
                                        'btn-active-danger':
                                          el <= c.score_zone.MIN_RANGE_MAX! &&
                                          el >= c.score_zone.MIN_RANGE_MIN!,
                                      }
                                    )}
                                    onClick={() => handleCriterionValue(c.id, el)}
                                  >
                                    {el}
                                  </button>
                                  <div
                                    className={clsx(
                                      'bg-primary rounded me-2 mt-1 h-5px',
                                      {
                                        'bg-success':
                                          el <= c.score_zone.MAX_RANGE_MAX! &&
                                          el >= c.score_zone.MAX_RANGE_MIN!,
                                      },
                                      {
                                        'bg-warning':
                                          el <= c.score_zone.AVERAGE_RANGE_MAX! &&
                                          el >= c.score_zone.AVERAGE_RANGE_MIN!,
                                      },
                                      {
                                        'bg-danger':
                                          el <= c.score_zone.MIN_RANGE_MAX! &&
                                          el >= c.score_zone.MIN_RANGE_MIN!,
                                      }
                                    )}
                                  ></div>
                                </div>
                              )
                            })}
                          </div>
                          <div className='d-flex flex-end'>
                            <div className='form-check form-check-custom mt-3 mb-2 ms-1'>
                              <span className='small fs-7 me-2 text-gray-600'>
                                {intl.formatMessage({id: 'CONTROL.IGNORE.CRITERION'})}
                              </span>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultChecked={ignoreCriterionState.indexOf(c.id) !== -1}
                                onChange={(e) => {
                                  let newIgnoreCriterion: string[] = ignoreCriterionState
                                  if (newIgnoreCriterion.indexOf(c.id) === -1) {
                                    newIgnoreCriterion.push(c.id)
                                    handleResetValue(c.id)
                                    setIgnoreCriterionState(newIgnoreCriterion)
                                  } else {
                                    setIgnoreCriterionState(
                                      newIgnoreCriterion.filter((number) => number !== c.id)
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className='overflow-hidden flex-column-fluid p-0 mb-4'>
                      <div className='rounded bg-gray-200 py-2 px-4'>
                        <div className='d-flex fw-bold small align-items-center'>
                          <div className='bd-highlight w-100 py-3'>
                            <div className='menu-title text-gray-900 text-uppercase'>comment</div>
                          </div>
                          {showComment ? (
                            <div
                              onClick={() => {
                                setShowComment(false)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x text-hover-primary cursor-pointer'
                              />
                            </div>
                          ) : (
                            <i
                              className='bi bi-pencil fs-5 text-gray-900i text-hover-primary cursor-pointer me-2'
                              onClick={() => setShowComment(true)}
                            />
                          )}
                        </div>
                        {showComment && (
                          <>
                            <div className='separator' />
                            <div className='pt-5 pb-1'>
                              <div className='input-group input-group-sm'>
                                <textarea
                                  className='form-control'
                                  placeholder='Comment'
                                  onChange={(e) => setComment(e.target.value)}
                                  value={comment}
                                ></textarea>
                              </div>
                            </div>
                            <div className='form-check form-check-custom mt-3 mb-2 ms-1'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultChecked={isShowOperator}
                                onChange={(e) => setIsShowOperator(e.target.checked)}
                              />
                              <span className='small fs-7 ms-2'>Show to operator</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-footer py-5'>
                    <div className='d-flex fw-bold small align-items-center'>
                      <div className='bd-highlight w-100'>
                        <div className='fw-bold text-gray-700 text-uppercase d-flex flex-column'>
                          {selectedForm?.types.map((type) => (
                            <div className='d-flex mb-2' key={type}>
                              <span className='label align-self-center'>
                                {intl.formatMessage({
                                  id:
                                    type === 'NUMBER_AVERAGE_SIMPLE'
                                      ? 'SCOREFORMS.METHOD.NUMBER.AVERAGE'
                                      : type === 'NUMBER_SUM_SIMPLE'
                                      ? 'SCOREFORMS.METHOD.NUMBER.SUM'
                                      : 'SCOREFORMS.METHOD.SIMPLE',
                                })}
                                :
                              </span>
                              <span className='ms-2 fw-bolded fs-4 text-gray-900'>
                                {score?.values &&
                                  score?.values.find((score) => score.score_type === type)?.value}
                                {type === 'PERCENT_SIMPLE' && '%'}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button
                        className='btn btn-sm btn-primary'
                        onClick={handleSaveScore}
                        disabled={findNullDetail()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className='card card-custom card-border'>
            <div className='card-header mb-3'>
              <span></span>
              <div className='card-title flex-column align-items-end justify-content-center'>
                <div className='card-label fw-bold text-gray-800 text-uppercase small m-0'>
                  {selectedForm?.name}
                </div>
                <div className='fw-bold label text-gray-700 small'>{selectedForm?.description}</div>
                <div className='label text-gray-700 small'>
                  {formatDateByZone(
                    control.score?.created_on!,
                    currentUser?.language,
                    currentUser?.time_zone
                  )}
                </div>
              </div>
            </div>
            <div className='card-body px-5 py-0'>
              {selectedForm &&
                selectedForm!.criterions?.map((c, i) => (
                  <div key={i} className='overflow-hidden flex-column-fluid p-0 mb-4'>
                    <div
                      className={clsx('rounded bg-gray-200 py-2 px-4', {
                        'bg-gray-300':
                          control.score?.detail.find((d) => d.criterion_id === c.id)
                            ?.criterion_value === -2,
                      })}
                    >
                      <div className='d-flex fw-bold small align-items-center'>
                        <div className='bd-highlight w-100 mt-2'>
                          <div className='menu-title text-gray-900 text-uppercase'>{c.name}</div>
                        </div>
                        <span className='fs-5'>
                          <i
                            className='bi bi-info-circle fs-2 text-hover-primary ms-2 cursor-pointer'
                            onClick={() => handleShowInfoCriterion(c)}
                          />
                        </span>
                      </div>
                      <div className='separator my-3'></div>
                      <div className='d-flex align-items-center mb-1'>
                        {buttonsBYRatingScale(c.rating_scale).map((el, i) => {
                          if (
                            c.rating_scale === 'BINARY_SCALE' ||
                            c.rating_scale === 'BINARY_TEXT_SCALE'
                          ) {
                            return (
                              <div key={i}>
                                <button
                                  className={clsx(
                                    'btn btn-sm btn-outline btn-outline-gray me-2',
                                    {
                                      active:
                                        control.score?.detail.find((d) => d.criterion_id === c.id)
                                          ?.criterion_value === el,
                                    },
                                    {'btn-active-success': el},
                                    {'btn-active-danger': !el}
                                  )}
                                  disabled
                                >
                                  {c.rating_scale === 'BINARY_TEXT_SCALE' ? (
                                    <span className='small'>{el === 0 ? <>NO</> : <>YES</>}</span>
                                  ) : (
                                    <span className='small'>{el}</span>
                                  )}
                                </button>
                                <div
                                  className={clsx(
                                    'bg-primary rounded me-2 mt-1 h-5px',
                                    {'bg-success': el},
                                    {'bg-danger': !el}
                                  )}
                                ></div>
                              </div>
                            )
                          }
                          return (
                            <div key={i}>
                              <button
                                className={clsx(
                                  'btn btn-sm btn-outline btn-outline-gray me-2',
                                  {
                                    active:
                                      control.score?.detail.find((d) => d.criterion_id === c.id)
                                        ?.criterion_value === el,
                                  },
                                  {
                                    'btn-active-success':
                                      el <= c.score_zone.MAX_RANGE_MAX! &&
                                      el >= c.score_zone.MAX_RANGE_MIN!,
                                  },
                                  {
                                    'btn-active-warning':
                                      el <= c.score_zone.AVERAGE_RANGE_MAX! &&
                                      el >= c.score_zone.AVERAGE_RANGE_MIN!,
                                  },
                                  {
                                    'btn-active-danger':
                                      el <= c.score_zone.MIN_RANGE_MAX! &&
                                      el >= c.score_zone.MIN_RANGE_MIN!,
                                  }
                                )}
                                disabled
                              >
                                {el}
                              </button>
                              <div
                                className={clsx(
                                  'bg-primary rounded me-2 mt-1 h-5px',
                                  {
                                    'bg-success':
                                      el <= c.score_zone.MAX_RANGE_MAX! &&
                                      el >= c.score_zone.MAX_RANGE_MIN!,
                                  },
                                  {
                                    'bg-warning':
                                      el <= c.score_zone.AVERAGE_RANGE_MAX! &&
                                      el >= c.score_zone.AVERAGE_RANGE_MIN!,
                                  },
                                  {
                                    'bg-danger':
                                      el <= c.score_zone.MIN_RANGE_MAX! &&
                                      el >= c.score_zone.MIN_RANGE_MIN!,
                                  }
                                )}
                              ></div>
                            </div>
                          )
                        })}
                      </div>
                      <div className='d-flex flex-end'>
                        <div className='form-check form-check-custom mt-3 mb-2 ms-1'>
                          <span className='small fs-7 me-2 text-gray-600'>
                            {intl.formatMessage({id: 'CONTROL.IGNORE.CRITERION'})}
                          </span>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            defaultChecked={
                              control.score?.detail.find((d) => d.criterion_id === c.id)
                                ?.criterion_value === -2
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {(!roleAccesses.includes('CONTROLSOWN') ||
                (roleAccesses.includes('CONTROLSOWN') &&
                  control?.score?.comments?.is_show_operator)) && (
                <div className='overflow-hidden flex-column-fluid p-0 mb-4'>
                  <div className='rounded bg-gray-200 py-2 px-4'>
                    <div className='d-flex fw-bold small align-items-center'>
                      <div className='bd-highlight w-100 py-3'>
                        <div className='menu-title text-gray-900 text-uppercase'>comment</div>
                      </div>
                    </div>
                    {control.score?.comments ? (
                      <>
                        <div className='separator mb-3'></div>
                        <span className='mb-3'>{control.score.comments.comment}</span>
                        <div className='separator mt-3' />
                        <div className='form-check form-check-custom mt-3 mb-2 ms-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            defaultChecked={control.score.comments.is_show_operator}
                            disabled
                          />
                          <span className='small fs-7 ms-2'>Show to operator</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='separator' />
                        <div className='pt-5'>
                          <span className='small text-uppercase'>no comment</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='card-footer py-5'>
              <div className='d-flex fw-bold small align-items-center'>
                <div className='bd-highlight w-100'>
                  <div className='fw-bold text-gray-700 text-uppercase d-flex flex-column'>
                    {selectedForm?.types.map((type) => (
                      <div className='d-flex mb-2' key={type}>
                        <span className='label align-self-center'>
                          {intl.formatMessage({
                            id:
                              type === 'NUMBER_AVERAGE_SIMPLE'
                                ? 'SCOREFORMS.METHOD.NUMBER.AVERAGE'
                                : type === 'NUMBER_SUM_SIMPLE'
                                ? 'SCOREFORMS.METHOD.NUMBER.SUM'
                                : 'SCOREFORMS.METHOD.SIMPLE',
                          })}
                          :
                        </span>
                        <span className='ms-2 fw-bolded fs-4 text-gray-900'>
                          {control.score?.values &&
                            control.score?.values.find((score) => score.score_type === type)?.value}
                          {type === 'PERCENT_SIMPLE' && '%'}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {infoCriterion && (
        <>
          <InfoCriterionModal
            criterion={infoCriterion}
            show={showModalInfoCriterion}
            handleClose={handleCloseInfoCriterion}
          />
        </>
      )}
    </>
  )
}

export {ControlForm}
