import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {UpdateScoreForm} from './modals/UpdateScoreForm'
import {DeleteFormModal} from './modals/DeleteFormModal'
import {RoleAccess} from '../../../../models/users/Users'
import {usersAPI} from '../../../../redux/services/UsersService'
import {scoreAPI} from '../../../../redux/services/ScoreService'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AddCriteriaModal} from '../criteria/modals/AddCriteriaModal'
import {FC, ReactNode, forwardRef, useEffect, useState} from 'react'
import {EditCriterionModal} from '../criteria/modals/EditCriterionModal'
import {InfoCriterionModal} from '../criteria/modals/InfoCriterionModal'
import {ExcludeCriteriaModal} from '../criteria/modals/ExcludeCriteriaModal'
import {ICriteria, IScoreForms, IStatus} from '../../../../models/score/Score'
import {buttonsBYRatingScale} from '../../../controls/helpers/control-helpers'
import {getAccesses} from '../../../../../_metronic/helpers/components/RoleAccesses'
import {formatDateByZone} from '../../../../../_metronic/helpers/FormatDateZoneHelpers'

interface ToggleButtonProps {
  children?: ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}
export type Ref = HTMLButtonElement

export const ToggleButton = forwardRef<Ref, ToggleButtonProps>((props, ref) => (
  <button
    ref={ref}
    className='btn btn-icon btn-sm btn-secondary w-25px h-25px'
    type='button'
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
  >
    {props.children}
  </button>
))

interface FormProps {
  status: IStatus
  form: IScoreForms
  isFirst?: boolean
}

const FormSingle: FC<FormProps> = ({status, form, isFirst}) => {
  const intl = useIntl()
  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [updateForm, {isLoading: isLoadingUpdateForm}] = scoreAPI.useFormsUpdateMutation()
  const [getFormInfo, {data: formInfo, isLoading: isLoadingGetCriterions}] =
    scoreAPI.useLazyFormsByIdQuery({})

  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  const [infoCriterion, setInfoCriterion] = useState<ICriteria>()
  const [excludeCriteriaForm] = scoreAPI.useFormsActionMutation()
  const [showModalEditFrom, setShowModalEditForm] = useState<boolean>(false)
  const [showModalDeleteForm, setShowModalDeleteForm] = useState<boolean>(false)
  const [showModalAddCriteria, setShowModalAddCriteria] = useState<boolean>(false)
  const [showModalInfoCriterion, setShowModalInfoCriterion] = useState<boolean>(false)
  const [showModalEditCriterion, setShowModalEditCriterion] = useState<boolean>(false)
  const [showModalDeleteCriteria, setShowModalDeleteCriteria] = useState<boolean>(false)
  const [isRemoveSingleCriterion, setIsRemoveSingleCriterion] = useState<string>()

  const handleCloseDeleteCriteria = () => setShowModalDeleteCriteria(false)
  const handleShowDeleteCriteria = () => setShowModalDeleteCriteria(true)

  const handleCloseAddCriteria = () => setShowModalAddCriteria(false)
  const handleShowAddCriteria = () => setShowModalAddCriteria(true)

  const handleCloseDeleteForm = () => setShowModalDeleteForm(false)
  const handleShowDeleteForm = () => setShowModalDeleteForm(true)

  const handleCloseEditForm = () => setShowModalEditForm(false)
  const handleShowEditForm = () => setShowModalEditForm(true)

  const hanleRemoveSingleCriterion = (id: string) => {
    setIsRemoveSingleCriterion(id)
    excludeCriteriaForm({
      id: formInfo!.id,
      action: 'remove',
      data: {criterion_ids: [id]},
    })
  }

  useEffect(() => {
    if (isRemoveSingleCriterion !== undefined) {
      setIsRemoveSingleCriterion(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo])

  const handleCloseInfoCriterion = () => {
    setInfoCriterion(undefined)
    setShowModalInfoCriterion(false)
  }
  const handleShowInfoCriterion = (cr: ICriteria) => {
    setInfoCriterion(cr)
    setShowModalInfoCriterion(true)
  }

  const handleCloseEditCriterion = () => {
    setInfoCriterion(undefined)
    setShowModalEditCriterion(false)
  }
  const handleShowEditCriterion = (cr: ICriteria) => {
    setInfoCriterion(cr)
    setShowModalEditCriterion(true)
  }

  function handleGetFormInfo() {
    if (formInfo === undefined) {
      getFormInfo({id: form.id})
    }
  }

  useEffect(() => {
    if (isFirst) {
      getFormInfo({id: form.id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirst])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <div className='card mb-6 mb-xl-9'>
      <div className='card-body p-5' onMouseEnter={() => handleGetFormInfo()}>
        <div className='d-flex flex-stack mb-3 fs-7'>
          <div
            className={clsx(
              'badge text-uppercase',
              {'badge-light-success': status === 'PUBLISHED'},
              {'badge-light-primary': status === 'DRAFT'},
              {'badge-light-warning': status === 'ARCHIVED'}
            )}
          >
            {status === 'PUBLISHED' && intl.formatMessage({id: 'PUBLISHED'})}
            {status === 'DRAFT' && intl.formatMessage({id: 'DRAFT'})}
            {status === 'ARCHIVED' && intl.formatMessage({id: 'ARCHIVED'})}
          </div>

          {roleAccesses.includes('FORMSMODIFY') && (
            <div className='d-flex align-items-center'>
              {status !== 'PUBLISHED' && status !== 'ARCHIVED' && (
                <>
                  <button
                    className='btn btn-icon btn-sm btn-secondary w-20px h-20px me-2'
                    onClick={handleShowAddCriteria}
                  >
                    <span className='svg-icon svg-icon-2x'>
                      <KTSVG path='/media/icons/duotune/general/gen035.svg' />
                    </span>
                  </button>
                  <AddCriteriaModal
                    object={{type: 'form', object: formInfo ? formInfo : form}}
                    show={showModalAddCriteria}
                    handleClose={handleCloseAddCriteria}
                  />
                </>
              )}
              <Dropdown drop='down' align='end'>
                <Dropdown.Toggle as={ToggleButton} id='dropdown-custom-components'>
                  <span className='svg-icon svg-icon-3'>
                    <KTSVG path='/media/icons/duotune/general/gen053.svg' />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className='menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'>
                  {status !== 'ARCHIVED' && (
                    <Dropdown.Item
                      bsPrefix='menu-item'
                      as='div'
                      className='px-3'
                      onClick={handleShowEditForm}
                    >
                      <div className='menu-link p-3 text-uppercase small'>
                        {intl.formatMessage({id: 'COMMON.BUTTON.EDIT'})}
                      </div>
                    </Dropdown.Item>
                  )}

                  {status === 'PUBLISHED' && (
                    <>
                      <Dropdown.Item
                        bsPrefix='menu-item'
                        as='div'
                        className='px-3 mt-1'
                        onClick={() => {
                          updateForm({id: form.id, data: {status: 'ARCHIVED'}})
                        }}
                        disabled={isLoadingUpdateForm}
                      >
                        <div className='menu-link p-3 text-uppercase small'>
                          {intl.formatMessage({id: 'CRITERION.ARCHIVE'})}
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                  {status === 'DRAFT' && (
                    <>
                      {formInfo?.criterions!?.length > 0 && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={() => {
                            updateForm({id: form.id, data: {status: 'PUBLISHED'}})
                          }}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                          </div>
                        </Dropdown.Item>
                      )}
                      {formInfo?.criterions!?.length > 0 && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={handleShowDeleteCriteria}
                        >
                          <div className='menu-link p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'GROUPS.DELETE.CRITERIA'})}
                          </div>
                        </Dropdown.Item>
                      )}
                      {roleAccesses.includes('FORMSDELETE') && (
                        <Dropdown.Item
                          bsPrefix='menu-item'
                          as='div'
                          className='px-3 mt-1'
                          onClick={handleShowDeleteForm}
                        >
                          <div className='menu-link text-danger p-3 text-uppercase small'>
                            {intl.formatMessage({id: 'CRITERION.DELETEDRAFT'})}
                          </div>
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                  {status === 'ARCHIVED' && (
                    <>
                      <Dropdown.Item
                        bsPrefix='menu-item'
                        as='div'
                        className='px-3 mt-1'
                        onClick={() => {
                          updateForm({id: form.id, data: {status: 'PUBLISHED'}})
                        }}
                      >
                        <div className='menu-link p-3 text-uppercase small'>
                          {intl.formatMessage({id: 'CRITERION.PUBLISH'})}
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <div className='mb-2'>
          <span className='fs-5 fw-bold mb-1 text-gray-900'>{form.name}</span>
        </div>
        <div className='fs-6 fw-semibold text-gray-700 mb-3'>{form.description}</div>
        <div className='fw-bold label text-gray-800 small'>
          {formatDateByZone(form.created_on!, currentUser?.language, currentUser?.time_zone)}
        </div>
        <span className='fw-bold label text-gray-600 small'>
          {form.departments.map((d, i, arr) => {
            if (arr.length - 1 === i) {
              return d.name
            } else {
              return d.name + ', '
            }
          })}
        </span>
        <div className='separator my-4'></div>
        <span className='d-flex fw-bolder small label my-2'>
          {intl.formatMessage({
            id: form.rating_scale,
          })}
        </span>
        <div className='text-gray-700 small mt-1 d-flex flex-column'>
          {form.types.map((d) => {
            return (
              <span
                className='label badge badge-secondary mb-2 me-3'
                style={{width: 'fit-content'}}
                key={d}
              >
                {intl.formatMessage({
                  id:
                    d === 'NUMBER_AVERAGE_SIMPLE'
                      ? 'SCOREFORMS.METHOD.NUMBER.AVERAGE'
                      : d === 'NUMBER_SUM_SIMPLE'
                      ? 'SCOREFORMS.METHOD.NUMBER.SUM'
                      : 'SCOREFORMS.METHOD.SIMPLE',
                })}
              </span>
            )
          })}
        </div>
        <div className='separator my-4'></div>
        {formInfo &&
          formInfo.criterions?.map((cr: ICriteria, i) => (
            <div key={cr.id} className='flex-column-fluid p-0 mb-2'>
              <div className='rounded bg-gray-200 py-2 px-4 z-index-0'>
                <div className='d-flex fw-bold small align-items-center'>
                  <div className='bd-highlight w-100'>
                    <div className='d-flex align-items-center menu-title text-gray-900 text-uppercase'>
                      {cr.name}
                      {roleAccesses.includes('CRITERIAMODIFY') && (
                        <i
                          className='bi bi-pencil text-hover-primary ms-3 cursor-pointer'
                          onClick={() => {
                            handleShowEditCriterion(cr)
                          }}
                        />
                      )}
                    </div>
                    <span className='text-gray-700 small'>{cr.rating_scale}</span>
                    <div className='d-flex mb-1'>
                      {buttonsBYRatingScale(cr.rating_scale).map((el, i) => {
                        if (
                          cr.rating_scale === 'BINARY_SCALE' ||
                          cr.rating_scale === 'BINARY_TEXT_SCALE'
                        ) {
                          return (
                            <div
                              key={i}
                              className={clsx(
                                'col-1 bg-primary rounded me-2 mt-1 h-2px',
                                {'bg-success': el},
                                {'bg-danger': !el}
                              )}
                            />
                          )
                        }
                        return (
                          <div
                            key={i}
                            className={clsx(
                              'bg-primary rounded me-2 mt-1 h-2px',
                              {
                                'bg-success':
                                  el + 1 <= cr.score_zone.MAX_RANGE_MAX! &&
                                  el + 1 >= cr.score_zone.MAX_RANGE_MIN!,
                              },
                              {
                                'bg-warning':
                                  el + 1 <= cr.score_zone.AVERAGE_RANGE_MAX! &&
                                  el + 1 >= cr.score_zone.AVERAGE_RANGE_MIN!,
                              },
                              {
                                'bg-danger':
                                  el + 1 <= cr.score_zone.MIN_RANGE_MAX! &&
                                  el + 1 >= cr.score_zone.MIN_RANGE_MIN!,
                              },
                              {col: cr.rating_scale === 'DECINARY_SCALE'},
                              {'col-1': cr.rating_scale !== 'DECINARY_SCALE'}
                            )}
                          />
                        )
                      })}
                    </div>
                    <div className='text-gray-800 fw-bold fs-7 small'>{cr.created_by.name}</div>
                  </div>
                  {status === 'DRAFT' ? (
                    <>
                      <i
                        className='bi bi-info-circle fs-2 text-hover-primary cursor-pointer ms-2 me-2'
                        onClick={() => handleShowInfoCriterion(cr)}
                      />
                      <OverlayTrigger
                        delay={{show: 50, hide: 50}}
                        overlay={
                          <Tooltip id='tooltip-title' style={{position: 'fixed'}}>
                            {intl.formatMessage({id: 'CRITERION.DELETE'})}
                          </Tooltip>
                        }
                      >
                        {isRemoveSingleCriterion !== undefined &&
                        isRemoveSingleCriterion === cr.id ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border align-middle  w-20px h-20px' />
                          </span>
                        ) : (
                          <i
                            className='bi bi-dash-circle fs-2 text-hover-primary cursor-pointer'
                            onClick={() => hanleRemoveSingleCriterion(cr.id)}
                          />
                        )}
                      </OverlayTrigger>
                    </>
                  ) : (
                    <i
                      className='bi bi-info-circle fs-2 text-hover-primary ms-2 cursor-pointer'
                      onClick={() => handleShowInfoCriterion(cr)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        <div className='d-flex flex-stack flex-wrapr mt-4'>
          <div className='text-gray-800 fw-bold fs-7 small'>{form.created_by.name}</div>
          <div className='border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3'>
            <span className='ms-1 fs-7 fw-bold text-gray-600'>
              Criterion(s) {form.criterions?.length}
            </span>
            {formInfo ? (
              <span className='fs-7 fw-bold text-gray-700 ms-2'>{formInfo.criterions?.length}</span>
            ) : (
              <>
                {isLoadingGetCriterions ? (
                  <span className='indicator-progress ms-2' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                ) : (
                  <span className='svg-icon svg-icon-1 ms-2 cursor-pointer'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr081.svg'
                      className='svg-icon svg-icon-2 text-hover-primary text-active-primary active'
                    />
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <UpdateScoreForm form={form} show={showModalEditFrom} handleClose={handleCloseEditForm} />
        {infoCriterion && (
          <>
            <InfoCriterionModal
              criterion={infoCriterion}
              show={showModalInfoCriterion}
              handleClose={handleCloseInfoCriterion}
            />
            <EditCriterionModal
              criterion={infoCriterion}
              show={showModalEditCriterion}
              handleClose={handleCloseEditCriterion}
            />
          </>
        )}
        <ExcludeCriteriaModal
          object={{type: 'form', object: formInfo ? formInfo : form}}
          criterions={formInfo && formInfo?.criterions ? formInfo.criterions : []}
          show={showModalDeleteCriteria}
          handleClose={handleCloseDeleteCriteria}
        />
        <DeleteFormModal
          form={form}
          show={showModalDeleteForm}
          handleClose={handleCloseDeleteForm}
        />
      </div>
    </div>
  )
}

export default FormSingle
