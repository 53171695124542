import {IDepartment} from '../departments/Departments'

export interface ICreator {
  id: string
  name: string
}

export type IStatus = 'DRAFT' | 'PUBLISHED' | 'ARCHIVED'

export interface IScoreZone {
  MAX_NAME?: 'NORMAL' | 'EXCELLENT' | 'CRITICAL'
  MAX_COLOR?: string
  MAX_DESCRIPTION?: string
  MAX_RANGE_MAX?: number
  MAX_RANGE_MIN?: number
  AVERAGE_NAME?: 'NORMAL' | 'EXCELLENT' | 'CRITICAL'
  AVERAGE_COLOR?: string
  AVERAGE_DESCRIPTION?: string
  AVERAGE_RANGE_MAX?: number
  AVERAGE_RANGE_MIN?: number
  MIN_NAME?: 'NORMAL' | 'EXCELLENT' | 'CRITICAL'
  MIN_COLOR?: string
  MIN_DESCRIPTION?: string
  MIN_RANGE_MAX?: number
  MIN_RANGE_MIN?: number
}

export interface IScoreZoneDescription {
  MAX_DESCRIPTION: string
  AVERAGE_DESCRIPTION: string
  MIN_DESCRIPTION: string
}

export type IAnyRatingScale = 'ANY'
export type IScoreType = 'PERCENT_SIMPLE' | 'NUMBER_AVERAGE_SIMPLE' | 'NUMBER_SUM_SIMPLE'

export interface ICreateScoreForms {
  name?: string
  description?: string
  score_types?: IScoreType[]
  status?: IStatus
  rating_scale?: IRatingScale | 'ANY' | undefined
  department_ids?: string[]
}

export type IRatingScale =
  | 'BINARY_SCALE'
  | 'BINARY_TEXT_SCALE'
  | 'TERNARY_SCALE'
  | 'QUATERNARY_SCALE'
  | 'QUINKARNARY_SCALE'
  | 'DECINARY_SCALE'

export interface ICriteria {
  id: string
  created_on: string
  created_by: ICreator
  name: string
  rating_scale: IRatingScale
  status: IStatus
  score_zone: IScoreZone
  groups?: IGroup
}

export interface IGroup {
  id: string
  name: string
  created_on: string
  created_by: ICreator
  departments?: IDepartment[]
  criterions?: ICriteria[]
}

export interface ICriterionsAction {
  criterion_ids: string[]
}

export interface IGroupCreate {
  name: string
  department_ids: string[]
}

export interface ICriteriaCreate {
  name?: string
  rating_scale?: IRatingScale

  status?: IStatus
  group_id?: string
  score_zone?: IScoreZone
}

export interface IObjectionGroup {
  id: string
  name: string
  created_on: string
  created_by?: ICreator
}

export interface IGroupObjectionCreate {
  name: string
}

export interface IGroupObjectionDelete {
  objections_move_to: string
}

export interface IGroupObjectionList {
  total: number
  objections: IScoreObjectionShort[]
}

export interface IScoreObjectionShort {
  id: string
  created_on: string
  created_by: ICreator
  name: string
  status: IStatus
}

export interface IScoreObjectionAddGroup {
  objection_ids: string[]
}

export interface IScoreObjection {
  id: string
  created_on: string
  created_by: ICreator
  name: string
  status: IStatus
  score_zone: IScoreZoneDescription
  group: IObjectionGroup
}

export interface IObjectionGroupsSelect {
  value: string
  label: string
}

export interface IScoreObjectionCreate {
  name?: string
  score_zone?: IScoreZoneDescription
  group_id?: string
}

export interface IScoreObjectionUpdate {
  name?: string
  status?: IStatus
  score_zone?: IScoreZoneDescription
  group_id?: string
}

export interface IScoreForms {
  id: string
  name: string
  description: string
  created_on: string
  created_by: ICreator
  status: IStatus
  departments: IDepartment[]
  score_zone?: IScoreZone
  criterions?: ICriteria[]
  types: IScoreType[]
  rating_scale: IRatingScale | 'ANY'
}

export const createScoreFormsInitialValues: ICreateScoreForms = {
  name: '',
  description: '',
  score_types: [],
  rating_scale: undefined,
  department_ids: [],
}

export const createGroupInitialValues: IGroupCreate = {
  name: '',
  department_ids: [],
}

export interface ListResponseCriterions<T> {
  total: number
  criterions: T[]
}

export interface ListResponseObjection<T> {
  total: number
  objections: T[]
}
