import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import FormSingle from './forms/FormSingle'
import {RoleAccess} from '../../../models/users/Users'
import {IScoreForms} from '../../../models/score/Score'
import {scoreAPI} from '../../../redux/services/ScoreService'
import {usersAPI} from '../../../redux/services/UsersService'
import {CreateScoreForms} from './forms/modals/CreateScoreForms'
import {getAccesses} from '../../../../_metronic/helpers/components/RoleAccesses'

const ScoreForms = () => {
  const intl = useIntl()
  const {data: forms, isLoading: loadingForms} = scoreAPI.useFormsFetchQuery()
  const [publishedForms, setPublishedForms] = useState<IScoreForms[]>([])
  const [draftForms, setDraftForms] = useState<IScoreForms[]>([])
  const [archivedForms, setArchivedForms] = useState<IScoreForms[]>([])
  const [showModalCreateScoreForm, setShowModalCreateScoreForm] = useState<boolean>(false)
  const [showArchive, setShowArchive] = useState<boolean>(false)

  const {data: currentUser} = usersAPI.useFetchGetMeQuery()
  const [roleAccesses, setRoleAccesses] = useState<Array<RoleAccess>>([])

  const handleCloseCreateScoreForm = () => setShowModalCreateScoreForm(false)
  const handleShowCreateScoreForm = () => setShowModalCreateScoreForm(true)

  useEffect(() => {
    if (forms && forms?.length > 0) {
      const pF: IScoreForms[] = []
      const dF: IScoreForms[] = []
      const aF: IScoreForms[] = []
      forms?.forEach((f) => {
        if (f.status === 'PUBLISHED') {
          pF.push(f)
        } else if (f.status === 'DRAFT') {
          dF.push(f)
        } else if (f.status === 'ARCHIVED') {
          aF.push(f)
        }
      })
      setPublishedForms(pF)
      setDraftForms(dF)
      setArchivedForms(aF)
      if (aF.length > 0) {
        setShowArchive(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms])

  useEffect(() => {
    if (currentUser) {
      setRoleAccesses(getAccesses(currentUser.role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      {forms && (
        <>
          <div className='d-flex justify-content-end align-items-center mb-2'>
            <span
              className={clsx(
                'd-flex align-items-center text-hover-warning text-gray-800 cursor-pointer text-active-warning me-2',
                {active: showArchive}
              )}
              onClick={() => setShowArchive(!showArchive)}
            >
              <i className='bi bi-eye fs-2 me-2' />
              <span className='small text-uppercase'>
                Archive {archivedForms.length > 0 ? archivedForms.length : 0}
              </span>
            </span>
          </div>
          <div className='row'>
            <div
              className={clsx(
                'col-lg-12',
                {'col-md-4 col-xl-4': showArchive},
                {'col-md-6 col-xl-6': !showArchive}
              )}
            >
              <div className='mb-9'>
                <div className='d-flex flex-stack'>
                  <div className='fw-bold fs-4'>{intl.formatMessage({id: 'PUBLISHED'})}</div>
                </div>
                <div className='h-3px w-100 bg-success'></div>
              </div>
              {loadingForms ? (
                <div className='d-flex card card-custom card-border'>
                  <div className='overlay overlay-block'>
                    <div className='card-header border-0 py-3'>
                      <div className='overlay-layer card-rounded bg-opacity-5'>
                        <div className='spinner-border text-primary' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                publishedForms.map((pF, i) => (
                  <FormSingle
                    key={pF.id}
                    status={pF.status}
                    form={pF}
                    isFirst={i === 0 ? true : false}
                  />
                ))
              )}
            </div>
            <div
              className={clsx(
                'col-lg-12',
                {'col-md-4 col-xl-4': showArchive},
                {'col-md-6 col-xl-6': !showArchive}
              )}
            >
              <div className='mb-9'>
                <div className='d-flex flex-stack'>
                  <div className='fw-bold fs-4'>{intl.formatMessage({id: 'DRAFT'})}</div>
                </div>
                <div className='h-3px w-100 bg-primary'></div>
              </div>
              {roleAccesses.includes('FORMSMODIFY') && (
                <>
                  {' '}
                  <button
                    className='btn btn-sm btn-primary w-100 mb-6'
                    onClick={handleShowCreateScoreForm}
                  >
                    {intl.formatMessage({id: 'TOOLS.SCOREFORMS.ADD.BUTTON'})}
                  </button>
                  <CreateScoreForms
                    show={showModalCreateScoreForm}
                    handleClose={handleCloseCreateScoreForm}
                  />
                </>
              )}

              {loadingForms ? (
                <div className='d-flex card card-custom card-border'>
                  <div className='overlay overlay-block'>
                    <div className='card-header border-0 py-3'>
                      <div className='overlay-layer card-rounded bg-opacity-5'>
                        <div className='spinner-border text-primary' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                draftForms.map((dF, i) => (
                  <FormSingle
                    key={dF.id}
                    status={dF.status}
                    form={dF}
                    isFirst={i === 0 ? true : false}
                  />
                ))
              )}
            </div>
            {showArchive && (
              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-9'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4'>{intl.formatMessage({id: 'ARCHIVED'})}</div>
                  </div>
                  <div className='h-3px w-100 bg-warning'></div>
                </div>
                {loadingForms ? (
                  <div className='d-flex card card-custom card-border'>
                    <div className='overlay overlay-block'>
                      <div className='card-header border-0 py-3'>
                        <div className='overlay-layer card-rounded bg-opacity-5'>
                          <div className='spinner-border text-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  archivedForms.map((aF, i) => (
                    <FormSingle
                      key={aF.id}
                      status={aF.status}
                      form={aF}
                      isFirst={i === 0 ? true : false}
                    />
                  ))
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ScoreForms
